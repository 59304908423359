@use "_fonts";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    @apply scroll-smooth;
    @apply font-sans-main font-normal;
    @apply antialiased;

    :is(h1, h2, h3, h4, h5, h6) {
      @apply font-sans-heading font-bold -tracking-wide text-black;
    }
    p,
    li {
      @apply text-[1.1rem] leading-[1.6] text-black;
    }
  }
  body {
    @apply xl:flex xl:flex-col xl:justify-center xl:items-center xl:min-h-screen;
    @apply xl:bg-gray-200;
  }
  #home-page {
    main {
      @apply h-screen w-screen p-0;
      @apply xl:overflow-hidden xl:w-[60%] xl:h-auto xl:rounded-[2.25rem] xl:shadow-sm;
      @apply flex flex-col;
    }
    header {
      @apply relative flex flex-col p-8 space-y-5;
      @apply xl:flex-wrap xl:items-end xl:justify-between xl:flex-row xl:space-x-5;
      @apply border-t-4 border-t-white;
      @apply xl:border-0;
      @apply shadow-[inset_0_0_0_99999px_rgba(0,0,0,0.7)] bg-cover bg-center;

      .profile-wrap {
        @apply z-[1] space-y-5;
        @apply xl:w-1/2 xl:ml-5;

        .avatar-wrap {
          .frame {
            @apply w-[8rem];
            @apply rounded-full border-white border-solid border-4 inline-block;
            img {
              @apply p-2 max-w-full rounded-full;
            }
          }
        }
        .copy {
          @apply space-y-6;
          p {
            @apply text-sm text-white leading-6;
            @apply first-of-type:-tracking-tight first-of-type:text-sm first-of-type:leading-6 first-of-type:uppercase first-of-type:font-semibold;
          }
          h1 {
            @apply text-white tracking-normal text-5xl;
          }
        }
      }
      .social-icons {
        @apply flex flex-row space-x-4 z-[1];

        a {
          @apply flex justify-center items-center w-14 h-14 rounded-full transition-transform ease-in-out duration-300;
          @apply hover:-translate-y-1;
          @apply bg-white;
          svg {
            @apply w-7 h-7;
            @apply fill-black;
          }
        }
      }
    }
    .content {
      @apply relative flex flex-col p-8 text-black bg-white;
      @apply xl:flex-row xl:justify-between xl:items-center xl:space-x-5;

      .copy {
        @apply space-y-5 mb-8;
        @apply xl:w-1/2 xl:ml-5 xl:mb-0;

        h2 {
          @apply text-3xl font-bold;
        }
        p {
          @apply text-sm text-gray-800 leading-6;
        }
      }

      .aside {
        @apply before:absolute before:block before:content-[''] before:border-t-[0.01rem] before:border-solid before:border-gray-400 before:h-0 before:ml-0 before:top-auto before:left-0 before:w-full;
        @apply before:xl:hidden;

        nav {
          @apply flex flex-col pt-8 space-y-5;
          @apply xl:mt-0 xl:pt-0;
          a {
            @apply flex items-center justify-between w-full min-w-full h-[3.5rem] py-0 px-7;
            @apply xl:px-5;
            @apply text-black text-base border-black border-[3px] border-solid font-semibold rounded-[3.5rem];
            @apply hover:bg-black hover:text-white hover:border-black;

            svg {
              @apply fill-black;
              @apply xl:ml-6;
            }

            &:hover svg {
              @apply fill-white;
            }
          }
        }
      }
    }
  }
}

@layer components {
  .modal {
    @apply hidden fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-90 z-30 flex-col justify-center items-center w-full h-full;
    .modal-window {
      @apply bg-white mx-6 w-5/6 h-4/5;
      @apply xl:w-3/4 xl:m-auto;
      .modal-header {
        @apply relative h-0;
        .modal-close {
          @apply absolute -top-14 right-0 ml-auto w-12 h-12 text-6xl text-white text-center cursor-pointer;
          @apply xl:-mr-14;
        }
      }
      .modal-body {
        @apply flex justify-center items-center overflow-hidden;
        iframe {
          @apply w-full h-[80vh];
        }
      }
    }
  }
}
